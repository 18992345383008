import Carousel from '../components/Carousel.js';
import ConcertTeaser from "../images/ConcertTeaser.png";
import StayInTouch from "../components/StayInTouch";

export default function Home() {
  return (
    <div>
      <body style={{textAlign: 'left'}}>
        <Carousel/>
        <div style={{margin: '10vh'}}>
          <h2><strong>Hello!</strong></h2>
          <p style={{fontSize: '21px'}}>Based in Boston, Massachusetts, the <strong>Horizon Ensemble</strong> performs music of history and music by friends. We believe in enriching our communities of musicians and audiences and supporting the development of young living composers!</p>
        </div>
      </body>
      <body className='outreach'>
        <img src={ConcertTeaser} className='ensemble-picture' style={{margin: "10vh"}}/>
        <div style={{margin: '10vh'}}>
          <h3><strong><u>Horizon Ensemble: Massachusetts Voices</u><br/>
          Sunday, April 6 at 3:00 PM</strong></h3>
          <p>
          <i>"Through all our history, to the last..."</i><br/><br/>
          As springtime flourishes, let's celebrate our very own <strong>Bay State</strong>. We'll begin with the winner of the Horizon Ensemble's inaugural <i>Call for Scores</i>: <strong>Coonarr</strong>, by Australian composer <strong>John Rotar</strong>, a breathlessly flowing portrait of natural beauty.
          Next, we'll premiere <strong>Paul Revere</strong> by our friend <strong>Ben Nacar</strong>, celebrating the 250th anniversary of the American hero, as inspired by the Henry Wadsworth Longfellow poem. 
          Turning to history, we'll perform <strong>Jephthah's Daughter</strong> by Massachusetts composer <strong>Amy Beach</strong>, a heartrending portrayal of the Biblical tale of sacrifice and faith. 
          Finally, we present <strong>Leonard Bernstein's</strong> momentous <strong>Jeremiah Symphony</strong>, depicting the prophet Jeremiah's desperate pleas for reform amongst the destruction and despair brought upon humanity.
          Our concert will feature Boston-based soprano <strong>Hannah Shanefield</strong> as a performer in Jephthah's Daughter and the Jeremiah Symphony. We'll also feature poetry by local poets <strong>Chia Lam</strong> and <strong>Farah Masud</strong>.

          <br/><br/>Learn more <a href="https://www.eventbrite.com/e/1276553809809/ ">here!</a>
          </p>
          </div>
      </body>
      <body className='season'>
        <a style={{color: "white"}} href="https://calendar.google.com/calendar/embed?src=horizonensembleboston%40gmail.com&ctz=UTC"><h3><u>Learn more about the Horizon Ensemble’s 2024–2025 season!</u></h3></a>
        </body>
      <body className='youtube'>
        <div className="displays">
          <h4 style={{fontSize: "21px"}}><strong>From a previous concert: 
            <br/>Florence Price’s The Oak <br/></strong></h4>
            <p>A mysterious orchestral exploration of history and dreams...
            </p>
            <div class="embed-responsive embed-responsive-16by9">
              <iframe class="embed-responsive-item" width="560" height="315" src="https://www.youtube.com/embed/vmaTdD_bC70?si=xj49QvZhAfLAyDv4" allowfullscreen></iframe>
              </div>
        </div>
        <div className="displays">
          <h4 style={{fontSize: "21px"}}><strong>Throwback to summer 2023: <br/></strong></h4>
          <p>A  lively Serenade for wind instruments, <br/>
            written by 4 Horizon composer friends!
            </p>
            <div class="embed-responsive embed-responsive-16by9">
              <iframe class="embed-responsive-item" width="560" height="315" src="https://www.youtube.com/embed/I9Rt2kAbbbc?si=oyUUlG9bMeZta9At" allowfullscreen></iframe>
              </div>
        </div>
      </body>
      <StayInTouch/>
    </div>
  );
}
